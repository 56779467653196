import React from 'react'
import { PartnerDetail } from './PartnerOnboarding'

export class Partner {
  constructor(
    public id: undefined | string,
    public name: string,
    public code: string,
    public settlementMethod: string,
    public callbackUrl?: string,
    public partnerDetail?: PartnerDetail | undefined,
  ) {}
}

export class AddPartner {
  constructor(public code: string, public name: string) {}
}

export enum EPartnerSettlementMethod {
  DEFAULT = 'default',
  AUTOSETTLE = 'autosettle',
  MANUAL = 'manual',
}

export type CreatePartnerProps = {
  createPartner: boolean
  setCreatePartner: React.Dispatch<React.SetStateAction<boolean>>
  tableRef: React.MutableRefObject<any>
}
