import { Avatar, Box, Divider, Drawer, Hidden, List, ListItem, Typography, makeStyles } from '@material-ui/core'
import { DashboardRounded, FindInPage, Toc } from '@material-ui/icons'
import { SessionContext } from 'contexts/SessionContext'
import { UserRoles } from 'models'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import NavItem from './NavItem'

type Route = {
  href: string
  icon: React.ReactNode
  title: string
  component: React.ReactNode
}

type Group = {
  title: string
}

type NavBarProps = {
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const AdminNavBar: React.SFC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()

  const [session, sessionDispatch] = useContext(SessionContext)
  const { user } = session

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  if (!session.user) return null

  const content = (
    <Box flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} component={RouterLink} to="/admin/profile" />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="body2"
          gutterBottom
          style={{ paddingLeft: 10, paddingRight: 10, textOverflow: 'clip' }}
        >
          {user?.email}
        </Typography>

        <Typography color="textSecondary" variant="body1" gutterBottom>
          {user?.name}
        </Typography>

        <Typography color="textSecondary" variant="body2" style={{ color: '#999', textAlign: 'center' }}>
          {user?.roles?.join(',')} {user?.partner?.name}
        </Typography>
      </Box>
      <Box p={2}>
        <Divider style={{ marginBottom: 16 }} />
        <List>
          <NavTitle title={t('admin.monitoring')} style={{ marginTop: 16, marginBottom: 16 }} />
          {session.user &&
          (session.user.hasRole(UserRoles.Admin) ||
            session.user.hasRole(UserRoles.AdminFinance) ||
            session.user.hasRole(UserRoles.AdminTech) ||
            session.user.hasRole(UserRoles.OperationFinance) ||
            session.user.hasRole(UserRoles.OperationSupport)) ? (
            <>
              <NavItem href="/admin/dashboard" title={t('admin.assets')} icon={DashboardRounded} />
              <NavItem href="/admin/withdraws" title={t('admin.withdraws')} icon={Toc} />
              <NavItem href="/admin/depositrequests" title={t('admin.deposit_requests')} icon={Toc} />
              <NavItem href="/admin/deposits" title={t('admin.deposit')} icon={Toc} />
              <NavItem href="/admin/callbacks" title={t('admin.callbacks')} icon={Toc} />
              <NavItem href="/admin/inquiryrrn" title={t('tools.inquiry_rrn')} icon={FindInPage} />
            </>
          ) : session.user && session.user.hasRole(UserRoles.Compliance) ? (
            <>
              <NavItem href="/admin/dashboard" title={t('admin.assets')} icon={DashboardRounded} />
            </>
          ) : null}
          {(session.user && session.user.hasRole(UserRoles.Admin)) || session.user.hasRole(UserRoles.AdminFinance) ? (
            <>
              <NavItem href="/admin/settlements" title={t('admin.settlements')} icon={Toc} />
            </>
          ) : null}
          {(session.user && session.user.hasRole(UserRoles.Admin)) || session.user.hasRole(UserRoles.AdminTech) ||session.user.hasRole(UserRoles.Compliance) ? (
            <>
              <NavItem href="/admin/audittrail" title={t('admin.audit_trail')} icon={Toc} />
            </>
          ) : null}
          <NavTitle title={t('admin.management')} style={{ marginTop: 16, marginBottom: 16 }} />
          {session.user &&
          (session.user.hasRole(UserRoles.Admin) || session.user.hasRole(UserRoles.AdminFinance) || session.user.hasRole(UserRoles.AdminTech) || session.user.hasRole(UserRoles.OperationSupport)) ? (
            <>
              <NavItem href="/admin/partners" title={t('partner.partners')} icon={Toc} />
              {(session.user && session.user.hasRole(UserRoles.Admin)) || session.user.hasRole(UserRoles.AdminFinance) || session.user.hasRole(UserRoles.AdminTech)  ? (
                <NavItem href="/admin/partnernetwork" title={t('admin.partner_network')} icon={Toc} />
              ) : null}
              <NavItem href="/admin/users" title={t('admin.users')} icon={Toc} />
              <NavItem href="/admin/depositaccounts" title={t('admin.deposit_accounts')} icon={Toc} />
            </>
          ) : session.user.hasRole(UserRoles.Admin) ||
            session.user.hasRole(UserRoles.AdminFinance) ||
            session.user.hasRole(UserRoles.AdminTech) ||
            session.user.hasRole(UserRoles.OperationSupport) ||
            session.user.hasRole(UserRoles.Compliance) ? (
            <>
              <NavItem href="/admin/partners" title={t('partner.partners')} icon={Toc} />
              <NavItem href="/admin/users" title={t('admin.users')} icon={Toc} />
            </>
          ) : null}
          {session.user &&
          (session.user.hasRole(UserRoles.Admin) || session.user.hasRole(UserRoles.AdminFinance) || session.user.hasRole(UserRoles.AdminTech) || session.user.hasRole(UserRoles.OperationFinance)) ? (
            <>
              <NavItem href="/admin/currencies" title={t('admin.currencies')} icon={Toc} />
              <NavItem href="/admin/networks" title={t('admin.networks')} icon={Toc} />
              <NavItem href="/admin/forex" title={t('admin.forexrates')} icon={Toc} />
              <NavItem href="/admin/fees" title={t('admin.fees')} icon={Toc} />
            </>
          ) : null}
          {session.user && (session.user.hasRole(UserRoles.Admin) || session.user.hasRole(UserRoles.Compliance)) ? (
            <>
              <NavItem href="/admin/dttot" title={t('admin.dttot')} icon={Toc} />
            </>
          ) : null}
          {session.user && (session.user.hasRole(UserRoles.Admin) || session.user.hasRole(UserRoles.AdminFinance)) ? (
            <>
              <NavTitle title={t('report.reporting')} style={{ marginTop: 16, marginBottom: 16 }} />
              <NavItem href="/admin/reports/fees" title={t('report.fees')} icon={Toc} />
            </>
          ) : null}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )
  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </div>
  )
}

const NavTitle = (props: { title: string; style?: any }) => (
  <div>
    <Divider style={props.style} />
    <ListItem style={{ fontWeight: 'bold', paddingLeft: 10 }}>{props.title}</ListItem>
  </div>
)

AdminNavBar.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMobileClose: () => {},
  openMobile: false,
}

const useStyles = makeStyles(() => ({
  root: {},
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    backgroundColor: '#ffffffe6',
    width: 256,
    // paddingTop: 35,
    height: '100%',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginTop: 10,
    marginBottom: 10,
  },
  name: {},
}))

export default AdminNavBar
