export class CookiesManager {
  public setCookie(name: string, value: string, expiresTimestamp: Date): void {
    const expires = new Date(expiresTimestamp)
    document.cookie = `${name}=${value};expires=${expires};path=/`
  }

  public getCookie(name: string): string {
    const cookieArray = document.cookie.split(';')
    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim()
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1)
      }
    }
    return ''
  }

  public deleteCookie(name: string): void {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`
  }

  public deleteAllCookie(): void {
    const cookies = document.cookie.split('; ')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
    }
  }
}
