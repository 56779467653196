import { Button, makeStyles } from '@material-ui/core'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import NavBar from './NavBar'
import TopBar from './TopBar'
import { Decoration } from './Decoration'
import { SessionContext } from 'contexts/SessionContext'
import { AdminRoutes } from './AdminRoutes'
import AdminNavBar from './AdminNavBar'
import { NotFoundPage } from './NotFoundPage'
import { UserRoles } from 'models'
import { Redirect, useHistory } from 'react-router-dom'

interface RouteProps {
  title: string
  href?: string
  icon?: ReactNode
  component?: ReactNode
}

export function AdminLayout() {
  const classes = useStyles()
  const history = useHistory()
  const [session] = useContext(SessionContext)

  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>()

  const onRefresh = () => {
    history.go(0)
  }

  useEffect(() => {
    // nothing?
  }, [session.user])

  if (
    !(
      session.user &&
      (session.user.hasRole(UserRoles.Admin) ||
        session.user.hasRole(UserRoles.AdminFinance) ||
        session.user.hasRole(UserRoles.AdminTech) ||
        session.user.hasRole(UserRoles.OperationFinance) ||
        session.user.hasRole(UserRoles.OperationSupport) ||
        session.user.hasRole(UserRoles.Compliance))
    )
  )
    return <Redirect to="/" />

  return (
    <>
      <Decoration
      // mode={mode}
      // gradient={gradient}
      // decoration={deco}
      // bgPosition={bgPosition}
      // horizontalMenu={false}
      />
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} className={classes.topbar} />

        <AdminNavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {/* <Button onClick={onRefresh}>Refresh</Button> */}
              <AdminRoutes />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  topbar: {
    backgroundColor: '#ef6161',
    // paddingLeft: 240,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 245,
    },
  },
  // topbarMobile: { paddingLeft: 0 },
  wrapper: {
    zIndex: 10,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}))
